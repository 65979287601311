import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import {
  ResponseMessage,
  ResponseMessageType,
} from '../interfaces/ApiResponse';

@Injectable()
export class HttpMessageInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body?.messages?.length > 0) {
          const messages = event.body?.messages as ResponseMessage[];
          messages.forEach((message: ResponseMessage) => {
            switch (
              ResponseMessageType[
                message.type as keyof typeof ResponseMessageType
              ]
            ) {
              case ResponseMessageType.Information:
                this.notificationService.notify(message.text);
                break;
              case ResponseMessageType.Warning:
                this.notificationService.warn(message.text);
                break;
              case ResponseMessageType.Undefined:
              case ResponseMessageType.Exception:
              case ResponseMessageType.Error:
                this.notificationService.error(message.text);
                break;
              default:
                // Handle unexpected message type
                break;
            }
          });
        }
      }),
      catchError((error) => {
        // Optionally handle errors here
        return throwError(error);
      })
    );
  }
}
