<div
  class="sidebar-area bg-white active"
  [ngClass]="{ active: isSidebarToggled }"
  [class.component-dark-theme]="themeService.isDark()"
  [class.dark-sidebar]="themeService.isSidebarDark()"
  [class.right-sidebar]="themeService.isRightSidebar()"
  [class.hide-sidebar]="themeService.isHideSidebar()"
  [class.card-borderd-theme]="themeService.isCardBorder()"
  [class.rtl-enabled]="themeService.isRTLEnabled()"
>
  <div class="logo bg-white">
    <a routerLink="/" class="d-flex align-items-center">
      <img
        src="https://www.xundinsleben.at/cms/assets/admin/images/logo2.png"
        alt="lucero"
      />
    </a>
  </div>
  <div
    (click)="toggle()"
    class="burger-menu"
    [ngClass]="{ active: isSidebarToggled }"
  >
    <span class="top-bar"></span>
    <span class="middle-bar"></span>
    <span class="bottom-bar"></span>
  </div>
  <ng-scrollbar visibility="hover" class="scrollbar-element">
    <div class="sidebar-inner">
      <div class="sidebar-menu">
        <mat-accordion>
          <span class="sub-title text-body"> VIEWS </span>
          <a
            routerLink="/"
            class="sidebar-menu-link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <i class="material-symbols-outlined"> person </i>
            <span class="title"> Users</span>
          </a>
          <a
            routerLink="/clients"
            class="sidebar-menu-link"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <i class="material-symbols-outlined"> groups </i>
            <span class="title"> Clients </span>
          </a>
        </mat-accordion>
      </div>
    </div>
    <div class="versions" *ngIf="versionsService.versions$ | async as versions">
      <div *ngFor="let version of versions">
        {{ version.product }}: {{ version.version }}
      </div>
    </div>
  </ng-scrollbar>
</div>
