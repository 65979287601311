<footer
  class="footer-area text-center bg-white border-top-radius"
  [class.card-borderd-theme]="themeService.isCardBorder()"
  [class.component-dark-theme]="themeService.isDark()"
>
  <p>
    © <span class="text-black fw-medium">Xund ins Leben </span> |
    <a href="https://www.xundinsleben.at/" target="_blank">LUCERO</a>
  </p>
</footer>
