import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private snackQueue: {
    message: string;
    action: string;
    config: MatSnackBarConfig;
  }[] = [];
  private isSnackbarVisible = false;

  constructor(private snackBar: MatSnackBar) {}

  private showNextSnackbar() {
    if (this.snackQueue.length > 0 && !this.isSnackbarVisible) {
      const snack = this.snackQueue.shift(); // Get the first message from the queue
      this.isSnackbarVisible = true;

      if (snack) {
        const snackBarRef = this.snackBar.open(
          snack.message,
          snack.action,
          snack.config
        );

        // Listen for when the snackbar is dismissed, then show the next one
        snackBarRef.afterDismissed().subscribe(() => {
          this.isSnackbarVisible = false;
          this.showNextSnackbar(); // Show the next message in the queue
        });
      }
    }
  }

  // General notification method
  notify(message: string, action: string = 'OK', duration: number = 5000) {
    const config: MatSnackBarConfig = {
      duration: duration,
      panelClass: ['info-snackbar'],
    };
    this.snackQueue.push({ message, action, config });
    this.showNextSnackbar(); // Try to display the message
  }

  // Warning notification
  warn(message: string, action: string = 'OK', duration: number = 15000) {
    const config: MatSnackBarConfig = {
      duration: duration,
      panelClass: ['warning-snackbar'],
    };
    this.snackQueue.push({ message, action, config });
    this.showNextSnackbar();
  }

  // Error notification (no auto-close)
  error(message: string, action: string = 'Dismiss') {
    const config: MatSnackBarConfig = {
      duration: 0, // Do not close automatically
      panelClass: ['error-snackbar'],
    };
    this.snackQueue.push({ message, action, config });
    this.showNextSnackbar();
  }
}
