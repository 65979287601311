import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { DateModule } from './core/modules/date.module';
//import { AuthenticationInterceptor } from './core/interceptors/authentication.interceptor';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { WithCredentialsInterceptor } from './core/interceptors/with-credentials.interceptor';
import { HttpMessageInterceptor } from './core/interceptors/http-messages.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(DateModule.forRoot()),
    // Hier werden alle Interceptors als Multi-Provider registriert
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpMessageInterceptor,
      multi: true,
    },

    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthenticationInterceptor,
    //   multi: true,
    // },
  ],
};
