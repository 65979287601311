import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ListResponse } from '../interfaces/ApiResponse';
import { environment } from '../../../environments/environment';

export interface VersionInfo {
  product: string;
  version: string;
}

@Injectable({
  providedIn: 'root',
})
export class VersionsService {
  constructor(private http: HttpClient) {}

  versions$: Observable<VersionInfo[]> = this.http
    .get<ListResponse<VersionInfo[]>>(`${environment.apiUri}/Version`)
    .pipe(map((response) => response.responseObject));
}
