<header
  class="header-area bg-white border-radius transition"
  [ngClass]="{ active: isSidebarToggled, sticky: isSticky }"
  [class.component-dark-theme]="themeService.isDark()"
  [class.right-sidebar]="themeService.isRightSidebar()"
  [class.hide-sidebar]="themeService.isHideSidebar()"
  [class.dark-header]="themeService.isHeaderDark()"
  [class.card-borderd-theme]="themeService.isCardBorder()"
  [class.rtl-enabled]="themeService.isRTLEnabled()"
>
  <div class="d-md-flex align-items-center justify-content-between">
    <div class="header-left-side d-flex align-items-center">
      <div
        class="burger-menu cursor-pointer transition d-xl-none"
        [ngClass]="{ active: isSidebarToggled }"
        (click)="toggle()"
      >
        <span class="top-bar d-block bg-black transition"></span>
        <span class="middle-bar d-block bg-black transition"></span>
        <span class="bottom-bar d-block bg-black transition"></span>
      </div>
      <div class="titles">
        <h1>{{ navigationService.pageTitle }}</h1>
        <h2
          *ngIf="navigationService.pageSubtitle"
          [innerHTML]="navigationService.pageSubtitle"
        ></h2>
      </div>
    </div>
    <ul
      class="header-right-side d-flex align-items-center mt-0 mb-0 pl-0 list-unstyled"
    >
      <li>
        <button mat-button class="dark-swtich-btn" (click)="toggleTheme()">
          <i class="material-symbols-outlined"> dark_mode </i>
        </button>
      </li>
    </ul>
  </div>
</header>
