import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let messages =
          error.error.messages?.length > 0
            ? error.error.messages
            : error.error.message?.length > 0
            ? [error.error.message]
            : [];
        switch (error.status) {
          case 404:
            // Handle 404 Not Found
            this.notificationService.warn(
              'Resource not found: ' + error.message
            );
            break;
          case 500:
            // Handle 500 Internal Server Error
            this.notificationService.error('Error 500: ' + error.error.message);
            break;
          default:
            // Handle other errors
            if (messages.length > 0) {
              messages.forEach((message: any) => {
                this.notificationService.error(
                  message.type + ': ' + message.text
                );
              });
            } else {
              this.notificationService.warn(
                'HTTP Error: ' + error.status + ' - ' + error.message
              );
            }
        }

        // Fehler weiterleiten, damit andere Interceptoren (z.B. AuthenticationInterceptor) ihn behandeln können
        return throwError(() => error);
      })
    );
  }
}
