import { Routes } from '@angular/router';
//import { AuthGuard } from '@auth0/auth0-angular';

export const routes: Routes = [
  {
    path: 'users',
    loadChildren: () =>
      import('./features/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./features/clients/clients.module').then((m) => m.ClientsModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/users/users.module').then((m) => m.UsersModule),
  },
  // {
  //   path: '',
  //   redirectTo: '/cockpit',
  //   pathMatch: 'full',
  // },
  // { path: '**', redirectTo: '/cockpit' },
];
