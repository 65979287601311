import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import {
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  DateAdapter,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import localeDe from '@angular/common/locales/de';
import { NativeDateAdapter } from '@angular/material/core';

// Register the locale data for the desired locale (e.g., German)
registerLocaleData(localeDe);

export class CustomDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    // Customize this function to return the desired first day of the week
    return 1; // Monday
  }
}

@NgModule({
  declarations: [],
  imports: [CommonModule, MatNativeDateModule, MatDatepickerModule],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD.MM.YYYY',
        },
        display: {
          dateInput: 'DD.MM.YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de-DE', // Change this to your desired locale
    },
    DatePipe,
  ],
  exports: [MatDatepickerModule],
})
export class DateModule {
  static forRoot(): ModuleWithProviders<DateModule> {
    return {
      ngModule: DateModule,
      providers: [
        {
          provide: DateAdapter,
          useClass: CustomDateAdapter,
          deps: [MAT_DATE_LOCALE],
        },
        {
          provide: MAT_DATE_FORMATS,
          useValue: {
            parse: {
              dateInput: 'DD.MM.YYYY',
            },
            display: {
              dateInput: 'DD.MM.YYYY',
              monthYearLabel: 'MMM YYYY',
              dateA11yLabel: 'LL',
              monthYearA11yLabel: 'MMMM YYYY',
            },
          },
        },
        {
          provide: MAT_DATE_LOCALE,
          useValue: 'de-DE', // Change this to your desired locale
        },
      ],
    };
  }
}
