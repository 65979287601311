export interface Pagination {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
}

export interface Message {
  type: string;
  text: string;
}

export interface ListResponse<T> {
  responseObject: T;
  messages: Message[];
  pagination: Pagination | null;
}

export interface SingleResponse<T> {
  messages: InfoMessage[];
  responseObject: T;
}

export interface InfoMessage {
  type: string;
  text: string;
}

export interface StatusResponseItem {
  success: boolean;
}

export interface ValueResponseItem {
  value: boolean;
}

export interface ResponseMessage {
  text: string;
  type: keyof typeof ResponseMessageType;
}

export enum ResponseMessageType {
  Undefined = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Exception = 4,
}
